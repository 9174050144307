<template>
  <app-dropdown-menu
    v-if="me"
    class="flex"
    :items="items"
    position="bottom-end"
  >
    <template #default="{ open }">
      <div
        class="flex items-center gap-2 rounded px-1 ring-primary-50 transition hover:bg-primary-50 hover:ring-4"
        :class="{
          'bg-primary-50 ring-4': open,
          'bg-white/20 ring-4': open && variant === 'transparent',
          'ring-white/20 hover:!bg-white/20': variant === 'transparent',
        }"
      >
        <template v-if="organizationMemberProfile && !noOrganizationLogo">
          <app-image
            :alt="organizationMemberProfile.organization.name"
            class="rounded bg-white p-1"
            :height="38"
            :src="organizationMemberProfile.organization.logo_url"
          />
          <app-divider class="!h-8" vertical />
        </template>
        <app-user-avatar
          :avatar-url="me.avatar_url"
          class="!h-10 !w-10"
          :name="me.full_name"
          :size="96"
        />

        <app-icon
          class="transition"
          :class="{ '-rotate-90': open }"
          :icon="open ? 'ph:caret-right' : 'ph:list'"
          :size="20"
        />
        <app-icon class="!hidden" icon="ph:caret-right" />
      </div>
    </template>

    <template #prepend>
      <user-identity-tile class="md:max-w-[300px]" :user="me" />
    </template>

    <template #append_learner_space>
      <app-chip class="bg-accent text-white" size="small"> ✨ New </app-chip>
    </template>
  </app-dropdown-menu>
</template>

<script lang="ts" setup>
import type { DropdownMenuItemDefinition } from "@pollen/ui-library/client/shared/dropdown/dropdown.model";

import type { MainHeaderVariant } from "~/layouts/_includes/main-header.model";

defineProps<{
  noOrganizationLogo?: boolean;
  variant?: MainHeaderVariant;
}>();

const { t } = useI18n();
const { me } = useEnrichedCurrentUser();
const { profile: organizationMemberProfile } = useOrganizationMemberProfile();
const { logout } = useCurrentUser();
const onboarding = useOnboarding();

const items = computed<DropdownMenuItemDefinition[]>(() => {
  return [
    ...(organizationMemberProfile.value?.role === "OWNER"
      ? [
          {
            id: "hub",
            label: "Hub - Espace client",
            appendIcon: "ph:arrow-square-out",
            href: "/auth/bridge/hub",
          },
        ]
      : []),
    {
      id: "learner_space",
      label: "Espace Learner",
      href: "/auth/bridge/learner",
    },
    {
      id: "onboarding",
      label: t("onboarding.navigation_label"),
      listeners: {
        click: () => onboarding.start({ origin: "main_navigation" }),
      },
    },

    { divider: true },
    {
      id: "logout",
      label: "Déconnexion",
      listeners: { click: logout },
    },
  ];
});
</script>
